import { Paper, Typography } from "@mui/material";
import EventsChart from "../components/charts/EventsChart";

const EventCategories = () => {
    return (
        <Paper sx={{padding: 3}}>
            <Typography component="h5" variant="h5" gutterBottom>
                Events
            </Typography>
            <EventsChart />


        </Paper>
    )
}

export default EventCategories
