import React, { useState } from "react"
import { WithId } from 'mongodb'

import BackendClient, { IStat } from "../lib/BackendClient"

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { XAxis, YAxis,ResponsiveContainer, Bar, BarChart, Tooltip, ScatterChart, Scatter, ReferenceArea, PieChart, Pie, ZAxis, CartesianGrid } from 'recharts';
import { Paper, Typography } from "@mui/material";

const columns: GridColDef[] = [
    { field: 'date', headerName: 'Date', width: 100, valueFormatter: params => new Date(params?.value).toLocaleDateString(), },
    { field: 'requests', headerName: 'Requests', width: 100 },
    { field: 'promptTokens', headerName: 'Prompt', width: 100 },
    { field: 'completionTokens', headerName: 'Completion', width: 100 },
    { field: 'totalTokens', headerName: 'Total Tokens', width: 100 },
    { field: 'maxTpm', headerName: 'Max TPM', width: 100 },
    { field: 'maxRpm', headerName: 'Max RPM', width: 100 },
    { field: 'Costs', headerName: 'Costs', width: 150, valueGetter: (params) => {
        let estimate = params.row.promptTokens/1000 * 0.03
        estimate += params.row.completionTokens/1000 * 0.06
        return `$${estimate.toFixed(2)}`
    }}
]

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: { cx: number, cy: number, midAngle: number, innerRadius: number, outerRadius: number, percent: number, index: number }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}% ${index === 0 ? 'better' : index === 1 ? 'same' : 'worse'}`}
      </text>
    );
};


const Topics = () => {
    const [initialized, setInitialized] = useState<boolean>(false)
    const [costs , setCosts] = useState<WithId<IStat>[]>([])
    const [costsPerRequest, setCostsPerRequest] = useState<number>(0)
    const [focusData, setFocusData] = useState<any>()
    const [phqData, setPHQData] = useState<any>()
    const [phqImprovement, setPHQImprovement] = useState<any>()
    const [questCategories, setQuestCategories] = useState<any>()


    React.useEffect(() => {
        const fetch = async () => {
            setInitialized(true)
            const resultFocus = await BackendClient.instance.focus()
            const mappedFocus = Object.keys(resultFocus).map((key) => ({category: key, value: resultFocus[key]}))
            setFocusData(mappedFocus)
            //console.log('focus:', mappedFocus)

            const resultPHQ = await BackendClient.instance.phq()
            setPHQData(resultPHQ.scatter)
            setPHQImprovement(resultPHQ.improvement)

            const resultQuestCategories = await BackendClient.instance.questCategories()
            setQuestCategories(resultQuestCategories)

            const costs = await BackendClient.instance.costs()
            setCosts(costs.reverse())

            let totalCosts = 0
            let totalRequests = 0
            for(const cost of costs) {
                totalCosts += cost.promptTokens/1000 * 0.03
                totalCosts += cost.completionTokens/1000 * 0.06
                totalRequests += cost.requests
            }
            setCostsPerRequest(totalCosts/totalRequests*100)
        }

        if(!initialized) fetch()
    })

    return (
        <>
        <Paper sx={{padding: 3, marginBottom: 3}}>
        <Typography component="h5" variant="h5" gutterBottom>
            Focus
        </Typography>
            <ResponsiveContainer width="100%" height={300} >
            <BarChart data={focusData} >
                <XAxis dataKey="category" />
                <YAxis scale="sqrt"/>
                <Tooltip />
                <Bar dataKey="value" fill="#54a160" />
            </BarChart>
            </ResponsiveContainer>
        </Paper>

        <Paper sx={{padding: 3, marginBottom: 3}}>
        <Typography component="h5" variant="h5" gutterBottom>
        Quest Categories
        </Typography>
            <ResponsiveContainer width="100%" height={300} >
            <BarChart data={questCategories} >
                <XAxis dataKey="_id" />
                <YAxis scale="sqrt"/>
                <Tooltip />
                <Bar dataKey="count" fill="#5888d6" />
            </BarChart>
            </ResponsiveContainer>
        </Paper>

        <Paper sx={{padding: 3, marginBottom: 3}}>
        <Typography component="h5" variant="h5" gutterBottom>
        PHQ-9
        </Typography>
            <Typography component="body">First 90 days, more than 1 answer</Typography>
            <ResponsiveContainer width="100%" height={500} >
                <ScatterChart margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }} >
                    <CartesianGrid />
                    <ReferenceArea y1={5} y2={9.5} label="light" stroke="red" strokeOpacity={0.3} />
                    <ReferenceArea y1={9.5} y2={14.5} label="moderate" stroke="red" strokeOpacity={0.3} />
                    <ReferenceArea y1={14.5} y2={27} label="heavy" stroke="red" strokeOpacity={0.3} />
                    <XAxis type="number" dataKey="day" domain={[0, 90]} tickCount={14}/>
                    <YAxis type="number" dataKey="value" name="phq" domain={[0, 27]} />
                    <ZAxis type="number" dataKey="count" name="measurements" range={[60, 400]} />
                    <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                    <Scatter data={phqData} fill="#5888d6" shape="triangle" />
                </ScatterChart>
            </ResponsiveContainer>

            <Typography component="body">Improvement after two weeks with 10+ inital phq</Typography>
            <ResponsiveContainer width="100%" height={600} >
                <PieChart width={600} height={600}>
                    <Pie
                    label={renderCustomizedLabel}
                    labelLine={false}
                    data={phqImprovement} dataKey="count" nameKey="label" cx="50%" cy="50%" outerRadius={200} fill="#54a160" />
                </PieChart>
            </ResponsiveContainer>

            </Paper>

            <Paper sx={{padding: 3, marginBottom: 3}}>
        <Typography component="h5" variant="h5" gutterBottom>
        Costs
        </Typography>
        <Typography component="body" gutterBottom>Costs per API request: ~{costsPerRequest.toFixed(2)} cents</Typography>
            <DataGrid
                rows={costs}
                columns={columns}
                getRowId={(row) => row._id.toString()}
            />
        </Paper>
        </>
    )
}

export default Topics;
