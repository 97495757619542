import { Box, Button, FormControl, FormHelperText, TextField} from "@mui/material";
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { IQRCode } from "../lib/BackendClient";

const schema = z.object({
    name: z.string().min(2).max(50),
    valid: z.string(),
    maxUsers: z.number().min(0),
    licenseLimit: z.number().min(0)
});

export type FormFields = z.infer<typeof schema>;

type Props = {
    didSubmit: (data: FormFields) => void;
    didCancel: () => void;
    defaults?: IQRCode;
}

const CodeForm = (props: Props) => {
    // by default, the expiration date is set to one year from now
    const defaultValidDate = new Date()
    defaultValidDate.setDate(defaultValidDate.getDate() + 365)

    const { register, handleSubmit, formState: {errors, isSubmitting} } = useForm<FormFields>({
        resolver: zodResolver(schema),
        defaultValues: {
            name: props.defaults?.name ?? '',
            valid: props.defaults?.expirationDate ? new Date(props.defaults.expirationDate).toISOString().split('T')[0] : defaultValidDate.toISOString().split('T')[0],
            maxUsers: props.defaults?.maxUsers ?? 100,
            licenseLimit: props.defaults?.licenseDurationDays ?? 365
        }
    });

    const onSubmit: SubmitHandler<FormFields> = (data: FormFields) => {
        // console.log(data);
        props.didSubmit(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl style={{ marginRight: 15 }}>
                <TextField
                    {...register('name')}
                    label="Name"
                    autoFocus={props.defaults ? false : true}
                    style={{ margin: 10, width: 300 }}
                />
                <FormHelperText error>{errors.name?.message}</FormHelperText>
            </FormControl>

            <FormControl style={{ marginRight: 15, width: 200 }}>
                <TextField
                    {...register('valid')}
                    label="Ablaufdatum"
                    type="date"
                    style={{ margin: 10, width: 150 }}
                    InputLabelProps={{ shrink: true }}
                />
                <FormHelperText error>{errors.valid?.message}</FormHelperText>
            </FormControl>

            <FormControl style={{ marginRight: 15, width: 200 }}>
                <TextField
                    {...register('maxUsers', {valueAsNumber: true})}
                    label="Max. Einlösungen"
                    type="number"
                    inputProps={{ min: 0, max: 1000 }}
                    style={{ margin: 10, width: 150 }}
                />
                <FormHelperText>0 = Kein Limit</FormHelperText>
                <FormHelperText error>{errors.maxUsers?.message}</FormHelperText>
            </FormControl>

            <FormControl style={{ marginRight: 15, width: 200 }}>
                <TextField
                    {...register('licenseLimit', {valueAsNumber: true})}
                    label="Laufzeit"
                    type="number"
                    inputProps={{ min: 1, max: 365 }}
                    style={{ margin: 10, width: 150 }}
                />
                <FormHelperText>Laufzeit pro Lizenz in Tagen</FormHelperText>
                <FormHelperText error>{errors.licenseLimit?.message}</FormHelperText>
            </FormControl>
            <Box>
                <Button
                    disabled={isSubmitting}
                    color="error"
                    onClick={() => props.didCancel()}
                    style={{ padding: 10, borderRadius: 10, margin: 10 }}>
                        Abbrechen
                </Button>

                <Button
                    onClick={handleSubmit(onSubmit)}
                    disabled={isSubmitting}
                    style={{ padding: 10, borderRadius: 10, margin: 10 }}>
                        {props.defaults ? 'Speichern' : 'Erstellen'}
                </Button>
            </Box>
        </form>
    );
}

export default CodeForm;