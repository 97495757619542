import React from "react"
import BackendClient from "../lib/BackendClient"
import { Paper, Typography } from "@mui/material"

const Settings = () => {
    const [config, setConfig] = React.useState<any>({})

    React.useEffect(() => {
        const fetch = async () => {
            const result = await BackendClient.instance.config()
            console.log('result:', result)
            setConfig(result)
        }

        if(Object.keys(config).length === 0) fetch()
    })



    return (
        <Paper sx={{padding: 3}}>
            <Typography component="h5" variant="h5" gutterBottom>
                Settings
            </Typography>
            <pre>{JSON.stringify(config)}</pre>

        </Paper>
    )
}

export default Settings
