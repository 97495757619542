import { Paper, Typography } from "@mui/material";
import ActivityChart from "../components/charts/ActivityChart";

const Usage = () => {
    return (
        <Paper sx={{padding: 3}}>
            <Typography component="h5" variant="h5" gutterBottom>
                Dashboard
            </Typography>

            <ActivityChart />

        </Paper>
    )
}

export default Usage;
